body {
	font-family: 'Open Sans', sans-serif;
	color: #2d2e31;
	font-size: 1rem;
	line-height: 1.6rem;
}

h1 {
	margin-top: 20px;
	margin-bottom: 10px;
	font-family: Montserrat, sans-serif;
	font-size: 2.375rem;
	line-height: 44px;
	font-weight: 500;
}

h2 {
	margin-top: 20px;
	margin-bottom: 10px;
	font-family: Montserrat, sans-serif;
	font-size: 2rem;
	line-height: 36px;
	font-weight: 500;
}

h3 {
	margin-top: 20px;
	margin-bottom: 10px;
	font-family: Montserrat, sans-serif;
	font-size: 1.5rem;
	line-height: 30px;
	font-weight: 500;
}

h4 {
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: Montserrat, sans-serif;
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 600;
}

h5 {
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: Montserrat, sans-serif;
	font-size: 0.875rem;
	line-height: 20px;
	font-weight: 600;
}

h6 {
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: Montserrat, sans-serif;
	font-size: 0.75rem;
	line-height: 18px;
	font-weight: 600;
	text-transform: uppercase;
}

p {
	margin-bottom: 0.7rem;
}

a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: underline;
}

.home-section {
	position: relative;
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 0rem;
	padding-bottom: 4rem;
	background-color: #696b6d;
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(90%, transparent), to(#696b6d)), url('/20150810114905/assets/images/bw_collage_7.png'), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(#696b6d));
	background-image: linear-gradient(180deg, transparent 90%, #696b6d), url('/20150810114905/assets/images/bw_collage_7.png'), linear-gradient(180deg, rgba(0, 0, 0, 0.5), #696b6d);
	background-position: 0px 0px, 100% 0px, 0px 0px;
	background-size: auto, cover, auto;
}

.green-accent-bkgd {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 43px;
	z-index: 998;
	width: 40%;
	height: 100%;
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(97%, transparent), color-stop(99%, #65b2e8)), url('/20150810114905/assets/images/lime_collage.jpg');
	background-image: linear-gradient(180deg, transparent 97%, #65b2e8 99%), url('/20150810114905/assets/images/lime_collage.jpg');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, contain;
	background-repeat: repeat, repeat-y;
}

.cb-div-4 {
	position: relative;
	z-index: 1000;
	display: block;
	width: 80%;
	height: auto;
	max-width: 1340px;
	min-width: 900px;
	margin-right: auto;
	margin-left: auto;
	padding: 4rem 8vw 4rem 7rem;
	background-color: hsla(0, 0%, 100%, 0.95);
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.logo-image {
	width: 100%;
}

.logo-link {
	width: 280px;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: none;
}

.container {
	padding-right: 4vw;
	padding-left: 4vw;
}

.container.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}



.navbar {
	position: static;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 1001;
	border-bottom: 3px solid #003a5d;
	background-color: #d3d3d3;
	min-height: 83px;
}

.nav-drop-wrap {
	position: relative;
	display: inline-block;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	text-decoration: none;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 100%, 0.5);
}

.nav-drop-toggle:hover {
	box-shadow: none;
}

.nav-drop-toggle.w--open {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: none;
	color: #fff;
}

.nav-drop-toggle.w--open:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

.main-nav-link {
	width: 100%;
	padding: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #003a5d;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.main-nav-link.w--current {
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -5px 0 0 rgba(0, 0, 0, 0.25);
}

.nav-drop-list {
	overflow: hidden;
}

.nav-drop-list.w--open {
	left: 0px;
	top: 100%;
	padding: 0.25rem 0em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #163a59;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.25)), to(rgba(0, 0, 0, 0.25)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
	color: #fff;
}

.nav-drop-list.open-left.w--open {
	background-color: transparent;
	background-image: none;
}

.nav-drop-list-link-2 {
	margin-bottom: 0.125rem;
	padding: 0.75rem 2rem 0.75rem 0.75rem;
	color: #cdcdcd;
	font-size: 0.875rem;
	line-height: 1.1rem;
	font-weight: 400;
	letter-spacing: 0.02rem;
}

.nav-drop-list-link-2:hover {
	background-color: rgba(45, 46, 49, 0.8);
	color: #fff;
}

.nav-drop-list-link-2.w--current {
	box-shadow: inset 3px 0 0 0 #163a59;
	color: #fff;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 10vw;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	display: flex;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.video-wrapper {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.background-video {
	height: 100vh;
}

.hero-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 75vh;
	min-height: 540px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.hero-caption {
	position: relative;
	z-index: 999;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding-bottom: 43px;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	font-family: Montserrat, sans-serif;
	font-weight: 200;
}

.hero-heading {
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 12px 0.5rem 1.25rem 4vw;
	background-color: rgba(0, 0, 0, 0.65);
	color: #fff;
	font-size: 3.1rem;
	line-height: 4.8rem;
	font-weight: 200;
	font-family: Montserrat, sans-serif;
}

.div-block-2 {
	margin-top: 28px;
	background-color: rgba(45, 46, 49, 0.9);
}

.dot-icon-wrap {
	position: absolute;
	left: -180px;
	top: 203px;
	z-index: 999;
	width: 850px;
	height: 850px;
	background-image: url('/20220519114905/assets/images/dickinson-law-icon_40-25wht.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.page-wrapper {
	position: relative;
	height: 100%;
	overflow: hidden;
}

.cb-div-2 {
	position: relative;
	z-index: 1000;
	padding: 3rem 5rem;
	background-color: hsla(0, 0%, 100%, 0.95);
}

.cb-div-2.spotlight {
	display: block;
	width: 50%;
	margin-top: 0%;
	margin-right: 0px;
	margin-left: 0%;
	padding: 4rem 4vw 4rem 7rem;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.cb-div-2.testimonials {
	display: block;
	width: 50%;
	margin-right: 0%;
	margin-left: 0px;
	padding: 4rem 4vw 5rem 7rem;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.cb-div-2.testimonials-image {
	display: block;
	width: 50%;
	margin-top: 0%;
	margin-right: 0px;
	margin-left: 0%;
	padding: 4rem 4vw 4rem 7rem;
	background-image: url('../images/testimonials_700x467.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.cb-div-2.banking-law-image {
	display: block;
	width: 50%;
	margin-right: 0%;
	margin-left: 0px;
	padding: 4rem 4vw 5rem 7rem;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.blog-item-wrap {
	padding-bottom: 1rem;
	border-bottom: 1px solid hsla(210, 2%, 42%, 0.4);
}

.blog-feed-title-link {
	color: #4b4c50;
	text-decoration: none;
}

.blog-feed-title-link:hover {
	background-color: #a1c973;
}

.cb-more,
.homebox a.cb-view-all {
	padding-right: 0.75rem;
	background-image: url('/20150810114905/assets/images/arrow-super-thick_black.svg');
	background-position: 92% 50%;
	background-size: 8px;
	background-repeat: no-repeat;
	font-family: Montserrat, sans-serif;
	color: #2d2e31;
	font-size: 0.9rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
	padding: 0.15rem 1rem 0.15rem 0.5rem;
	line-height: 1.5rem;
}

.cb-more:hover,
.homebox a.cb-view-all:hover {
	background-color: transparent;
}

.cb-div-3 {
	position: relative;
	z-index: 1000;
	display: none;
	width: 51.11%;
	margin-right: 22.22%;
	margin-bottom: 4.44%;
	margin-left: 5.55%;
	padding: 5rem 8vw 5rem 7rem;
	background-color: hsla(0, 0%, 100%, 0.95);
}

.blog-date {
	display: none;
	font-size: 0.8rem;
	font-weight: 600;
	text-transform: uppercase;
}

.blog-feed-heading {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	font-family: Montserrat, sans-serif;
	font-size: 1.5rem;
	line-height: 1.875rem;
	font-weight: 500;
}

.cb-icon-wrap {
	position: absolute;
	left: -20px;
	top: 51px;
	width: 100px;
	height: 80px;
	background-image: url('/20150810114905/assets/images/quotemarks_garamond-blk.svg');
	background-position: 0px 50%;
	background-size: 90px;
	background-repeat: no-repeat;
	opacity: 1;
}

.cb-icon-wrap.quote-icon {
	width: 95px;
	height: 75px;
	background-image: url('/20150810114905/assets/images/quotemarks_garamond-919191.svg');
	background-size: 90px;
}

.cb-icon-wrap.scales-icon {
	top: 51px;
	width: 100px;
	height: 80px;
	background-image: url('/20150810114905/assets/images/balance-scale-solid_919191.svg');
	background-position: 0px 50%;
	background-size: 100px;
}

.cb-icon-wrap.person-icon {
	left: -17px;
	top: 20px;
	width: 95px;
	height: 88px;
	background-image: url('/20150810114905/assets/images/user-alt-solid_919191.svg');
	background-size: 90px;
}

.cb-icon-wrap.blog-icon {
	left: -18px;
	top: 47px;
	height: 94px;
	background-image: url('/20150810114905/assets/images/pen-square-solid_919191.svg');
	background-size: 85px;
}

.testimonial-name {
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	text-transform: uppercase;
}

.cb-3-heading {
	position: relative;
	left: -1rem;
	margin-top: 0px;
	margin-bottom: 2rem;
	padding-left: 0.75rem;
	border-left: 2px solid hsla(210, 2%, 42%, 0.5);
	font-family: Montserrat, sans-serif;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 300;
}

.cb-text {
	margin-bottom: 1.5rem;
	font-size: 1.1rem;
	line-height: 1.7rem;
}

.footer-block {
	position: relative;
	z-index: 1001;
	display: block;
	margin-bottom: 1.75rem;
}

.footer-link {
	border-bottom: 1px solid #696b6d;
	color: #003a5d;
	text-decoration: none;
}

.footer-link:hover {
	border-bottom-color: transparent;
	color: #003a5d;
}

.footer-paragraph {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0px;
	margin-top: 0.25rem;
	margin-left: 3rem;
	padding-left: 32px;
	float: none;
	background-position: 50% 50%;
	background-size: 130px 28px;
	background-repeat: no-repeat;
	opacity: 0.5;
	-webkit-transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, border-width 200ms ease, opacity 200ms ease;
	transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, border-width 200ms ease, opacity 200ms ease;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.5rem;
	line-height: 0.625rem;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-grip-link:hover {
	opacity: 0.75;
	color: #fff;
}

.footer-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1500px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr 1fr 0.75fr;
	grid-template-columns: 1fr 1fr 1fr 0.75fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.5rem;
}

.copyright-text h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	color: #003a5d;
	font-size: 0.78rem;
	line-height: 1.25rem;
	font-weight: normal;
	font-family: 'Open Sans', sans-serif;
}

.footer-block-inside-wrap {
	margin-bottom: 1.5rem;
}

.footer-address-block {
	padding-left: 0.85rem;
	float: left;
}

.footer-icon-image {
	display: none;
	width: 35px;
	height: 50px;
	margin-right: 20px;
	margin-left: 5px;
	float: left;
	opacity: 0.75;
}

.section.footer-section {
	position: relative;
	z-index: 1000;
	padding: 3rem 11.11% 2rem;
	background-color: #d3d3d3;
	color: #003a5d;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.footer-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 28%;
	padding-right: 2.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-column-wrap.contact-info-wrap {
	line-height: 1.375rem;
}

.footer-column-wrap.badges-wrap {
	width: 21%;
	padding-right: 0rem;
	line-height: 1.375rem;
}

.small-button {
	display: inline-block;
	min-width: 100px;
	margin: 0px 0px 10px 0.85em;
	padding: 0.5rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
	border-radius: 4px;
	background-color: #003a5d;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #d3d3d3;
	font-size: 0.7rem;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.small-button:hover {
	box-shadow: 0 0 0 2px #003a5d;
}

.signoff-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.78rem;
	flex: 1 1 50%;
}

.sign-off-right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.link-2 {
	height: 100%;
	margin-left: 5px;
	border-bottom: 2px none #003a5d;
	box-shadow: 0 2px 0 0 #003a5d;
	color: #003a5d;
	font-weight: 600;
	text-decoration: none;
	line-height: 1.7;
}

.link-2:hover {
	border-bottom-style: none;
	box-shadow: none;
}

.paragraph {
	margin-bottom: 0rem;
}

.image-5 {
	height: 20px;
	margin-left: 15px;
}

.social-media-link {
	width: 32px;
	height: 32px;
	margin-right: 0.25rem;
	padding: 3px;
}

.social-media-link:hover {
	opacity: 0.75;
}

.social-media-link.twitter {
	padding: 0;
	width: 35px;
}

.social-media-link.twitter img {
	margin-left: -4px;
	top: 3px;
	position: relative;
}

.link {
	border-bottom: 1px dotted #737365;
	text-decoration: none;
}

.link:hover {
	border-bottom: 1px dotted #b69b30;
}

.link.footer-signoff {
	display: inline-block;
	margin-right: 0.75rem;
	margin-left: 0rem;
	border-bottom-style: solid;
	border-bottom-color: #696b6d;
	color: #003a5d;
}

.link.footer-signoff.green {
	color: #003a5d;
}

.link.footer-signoff:hover {
	border-bottom-color: transparent;
	color: #003a5d;
}

.footer-heading {
	position: relative;
	padding-left: 0.75rem;
	border-left: 2px solid hsla(210, 2%, 42%, 0.5);
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-transform: uppercase;
}

.signoff-outer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1.5rem;
	padding-right: 0rem;
	padding-left: 0.85rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 0.78rem;
}

.section-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 20px;
	padding-bottom: 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #1e1e20;
}

.badge-image {
	max-width: 150px;
	width: 100%;
	margin-bottom: 1rem;
	-webkit-filter: saturate(75%);
	filter: saturate(75%);
}

.badge-image.best-law-firm {
	max-width: 90px;
}

.signoff-left-top {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.signoff-left-bottom {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.cb-div-1 {
	position: relative;
	z-index: 1000;
	display: block;
	width: 80%;
	max-width: 1340px;
	min-width: 900px;
	margin-right: auto;
	margin-left: auto;
	padding: 2.5rem 8vw 1rem 7rem;
	background-color: hsla(0, 0%, 100%, 0.95);
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.social-wrap {
	margin-bottom: 30px;
	padding-left: 0.85rem;
}

.phone-link {
	color: #003a5d;
	text-decoration: none;
}

.left-nav-section-title {
	margin-top: 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.session-message-ul {
	margin-bottom: 0rem;
}

.ordered-list {
	padding-left: 20px;
}

.form-styles {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.heading-link {
	border-bottom: 1px solid #a1c973;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #696b6d;
	text-decoration: none;
	cursor: pointer;
}

.heading-link:hover {
	border-bottom-color: transparent;
	color: #7c8186;
}

.left-nav-wrapper {
	margin-right: 1rem;
	margin-left: 0rem;
}

.error-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #e81c2e;
	border-bottom: 2px solid #e81c2e;
	background-color: rgba(255, 190, 195, 0.4);
	font-weight: 500;
}

.intro-paragraph {
	margin-bottom: 1.5rem;
	color: #424242;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	max-width: 250px;
	min-height: 300px;
	min-width: 200px;
	margin-right: 3vw;
	padding-top: 1rem;
	padding-right: 0.75px;
	padding-bottom: 4rem;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.left-nav-section-title-link,
.left-nav-section-title-link:hover {
	display: block;
	padding-top: 0.25rem;
	padding-right: 0px;
	padding-bottom: 0.4rem;
	color: #2d2e31;
	font-size: 1.25rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-decoration: none;
	border: 0px;
}

.inside-content-section {
	padding-top: 153px;
}

.textlink-middleout {
	border-bottom: 1px solid #a1c973;
	color: #696b6d;
	font-weight: 500;
	text-decoration: none;
}

.textlink-middleout:hover {
	border-bottom-color: transparent;
	box-shadow: inset 0 -1px 0 0 #629edc;
}

.button {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding: 0.8rem 1rem 0.7rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 4px;
	background-color: #003a5d;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #d3d3d3;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.button:hover {
	box-shadow: 0 0 0 2px #003a5d;
}

.button.secondary {
	background-color: #d6dce0;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2e2e2e;
	text-align: center;
	cursor: pointer;
}

.button.secondary:hover {
	border-color: #221f1f;
	background-color: #fff;
	background-image: none;
	opacity: 1;
}

.button.tertiary {
	min-width: 60px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.25rem 0.75rem;
	border: 2px solid #c4ccd1;
	background-color: transparent;
	color: #585b5d;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.button.tertiary:hover {
	background-color: #f2f2f2;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #000;
}

.button.atty-search-button {
	display: block;
	width: 100%;
	height: 38px;
	margin: 10px auto 0px;
	padding: 0.7rem 0rem 0.5rem;
	border: 0px;
	text-indent: -900px;
	background-image: url('/20150810114905/assets/images/search_attorney.svg');
	background-size: 17px 17px;
	background-repeat: no-repeat;
	background-position: center;
}

.button.search-button {
	width: 30%;
	height: 2.5rem;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	padding-top: 0.38rem;
	padding-bottom: 0.38rem;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	background-color: #003a5d;
	line-height: 1.9rem;
	font-weight: 600;
	text-indent: -900px;
	background-image: url('/20150810114905/assets/images/search_attorney.svg');
	background-size: 17px 17px;
	background-repeat: no-repeat;
	background-position: center;
}

.button.search-button:hover {
	box-shadow: -4px 0 0 0 #003a5d, 4px 0 0 0 #003a5d;
}

.highlight {
	background-color: #f8ff5a;
}

.left-nav-li.greatgrandchild-li {
	margin-top: 2px;
	margin-left: 0.85rem;
	font-size: 0.9rem;
}

.left-nav-li.grandchild-li {
	margin-left: 0.75rem;
}

.right-column-link {
	border-bottom: 1px solid #a1c973;
	color: #696b6d;
	text-decoration: none;
}

.right-column-link:hover {
	border-bottom: 1px solid transparent;
}

.container-2 {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container-2.inside-content-container {
	padding-top: 2rem;
	padding-right: 6.5vw;
	padding-left: 6.5vw;
	background-color: hsla(0, 0%, 100%, 0.9);
}

.container-2.inside-page-container {
	max-width: 1200px;
}

.right-column-styles-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.div-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.15);
}

.breadcrumbs-wrapper {
	display: block;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.text-link {
	border-bottom: 1px solid #a1c973;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #696b6d;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
}

.main-content {
	display: block;
	width: 100%;
	padding-top: 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.breadcrumbs-list {
	margin-bottom: 2rem;
	padding-left: 0px;
}

.field-label {
	font-weight: 500;
}

.right-side-col {
	position: relative;
	width: 20%;
	max-width: 250px;
	min-width: 160px;
	margin-left: 3vw;
	padding-top: 1rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #424242;
	font-size: 1rem;
}

.block-quote {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-color: #003a5d;
	color: #696b6d;
	font-size: 1.35rem;
	line-height: 2rem;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
}

.left-nav-ul {
	display: block;
	margin-bottom: 0.5rem;
	background-color: transparent;
}

.breadcrumb-link {
	border-bottom: 1px solid #003a5d;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	text-decoration: none;
	cursor: pointer;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #696b6d;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
}

.breadcrumb-link.current-bc-link:hover {
	color: #424242;
}

.left-nav-link-2 {
	display: block;
	margin-bottom: 0.5rem;
	padding: 2px 0rem 2px 0.63rem;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	color: #000;
	font-size: 0.83rem;
	line-height: 1.2rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-link-2:hover {
	padding-right: 0rem;
	padding-left: 0.63rem;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #2a5ea3;
}

.left-nav-link-2.w--current {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.left-nav-link-2.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-2.greatgrandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	background-image: url('/20150810114905/assets/images/bullet_ffffff.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 500;
	text-transform: none;
}

.left-nav-link-2.greatgrandchild-link:hover {
	box-shadow: none;
}

.left-nav-link-2.greatgrandchild-link.w--current {
	background-image: url('/20150810114905/assets/images/bullet_629edc.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 500;
}

.left-nav-link-2.grandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	background-image: url('/20150810114905/assets/images/bullet_ffffff.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	font-size: 0.93rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-transform: none;
}

.left-nav-link-2.grandchild-link.w--current {
	background-image: url('/20150810114905/assets/images/bullet_629edc.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
}

.right-col-item-wrap {
	padding-bottom: 0.4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.success-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #4fb402;
	border-bottom: 2px solid #4fb402;
	background-color: hsla(94, 73%, 76%, 0.55);
	font-weight: 500;
}

.heading-2 {
	font-family: Montserrat, sans-serif;
	font-weight: 600;
}

.mobile-image {
	display: none;
}

.megamenu-container {
	position: absolute;
	top: auto;
	z-index: 100;
	display: block;
	height: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	opacity: 0;
	transition: opacity 500ms ease 0s;
	padding-top: 3px;
}

.accesible-navigation-menu li .megamenu-container.container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: auto;
	z-index: 100;
	min-width: auto;
	left: auto;
	padding-top: 3px;
	transition: opacity 500ms ease 0s;
	height: 0;
	color: #cdcdcd;
}

.mega-inner-wrap {
	display: none;
}

.meda-title-area {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 0.75rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
}

.mega-lower {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.search-alpha {
	width: 326px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.search-divider {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 90px;
	padding-top: 5px;
	padding-bottom: 5px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.filter-search-wrap {
	padding-bottom: 1rem;
}

.alpha-link {
	display: inline-block;
	width: 17px;
	margin-right: 0.5rem;
	margin-bottom: 0.125rem;
	padding: 0.25rem 0.125rem;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.1rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
	text-decoration: none;
	text-transform: uppercase;
}

.alpha-link:hover {
	color: #fff;
	text-decoration: underline;
}

.alpha-link.w--current {
	box-shadow: inset 3px 0 0 0 #163a59;
	color: #fff;
}

.alpha-link.no-names {
	color: #cdcdcd;
	font-weight: 300;
}

.alpha-link.no-names:hover {
	text-decoration: none;
}

.mega-heading {
	display: inline-block;
	margin-top: 0px;
	margin-bottom: 0px;
	color: #fff;
	font-size: 1rem;
	line-height: 1rem;
	text-transform: uppercase;
}

.mega-view-all {
	padding-right: 0.75rem;
	background-image: url('/20150810114905/assets/images/arrow-super-thick_white.svg');
	background-position: 100% 47%;
	background-size: 8px;
	background-repeat: no-repeat;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.85rem;
	line-height: 0.85rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.mega-view-all:hover {
	color: #fff;
}

.letters-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.search-section-title {
	margin-bottom: 1rem;
	color: #fff;
	font-size: 0.9rem;
	line-height: 0.9rem;
	font-weight: 300;
	margin-top: 20px;
	font-family: Montserrat, sans-serif;
}

.form-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.form-wrap div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
}

.atty-search-firstname {
	width: 49%;
	border-radius: 4px;
}

.atty-search-firstname::-webkit-input-placeholder {
	color: #2d2e31;
}

.atty-search-firstname:-ms-input-placeholder {
	color: #2d2e31;
}

.atty-search-firstname::-ms-input-placeholder {
	color: #2d2e31;
}

.atty-search-firstname::placeholder {
	color: #2d2e31;
}

.atty-search-lastname {
	width: 49%;
	border-radius: 4px;
}

.atty-search-lastname::-webkit-input-placeholder {
	color: #2d2e31;
}

.atty-search-lastname:-ms-input-placeholder {
	color: #2d2e31;
}

.atty-search-lastname::-ms-input-placeholder {
	color: #2d2e31;
}

.atty-search-lastname::placeholder {
	color: #2d2e31;
}

.atty-search-dept {
	width: 49%;
	margin-bottom: 0px;
	padding-left: 8px;
	border-radius: 4px;
}

.atty-search-location {
	width: 49%;
	margin-bottom: 0px;
	padding-right: 8px;
	padding-left: 8px;
	border-radius: 4px;
}

.vert-line {
	width: 1px;
	height: 40%;
	border-left: 1px solid hsla(0, 0%, 100%, 0.5);
}

.or {
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.1rem;
	text-transform: uppercase;
}

.header-section {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 1001;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.mega-inner-wrap {
	padding-right: 2rem;
	padding-left: 2rem;
	background-color: rgba(45, 46, 49, 0.9);
}

.nav-drop-toggle-mega {
	position: relative;
	z-index: 1004;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 100%, 0.5);
}

.nav-drop-toggle-mega:hover {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: none;
	color: hsla(0, 0%, 100%, 0.75);
}

.nav-drop-toggle-mega.w--open {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: none;
	color: #fff;
}

.nav-drop-toggle-mega.w--open:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

.green-accent-bkgd-lower {
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: 998;
	width: 40%;
	height: 100%;
	background-color: #65b2e8;
}

.footer-upper-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.mobile-menu-arrow {
	display: none;
}

.fixed-nav-button-wrap {
	position: relative;
	top: 0px;
	z-index: 3001;
	display: block;
	width: 70px;
	padding-top: 30px;
	padding-bottom: 30px;
	border-bottom-style: none;
}

.fixed-nav-button-wrap:hover {
	text-decoration: none;
}

.fixed-nav-button-wrap:active {
	text-decoration: none;
}

.fixed-nav-button-wrap:focus {
	text-decoration: none;
}

.nav-button-line-1 {
	display: block;
	width: 30px;
	height: 4px;
	min-width: 0px;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.nav-button-line-2 {
	display: block;
	width: 30px;
	height: 4px;
	min-width: 0px;
	margin: 5px auto;
	background-color: #fff;
}

.nav-button-line-3 {
	display: block;
	width: 30px;
	height: 4px;
	min-width: 0px;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
}

.cb-view-all {
	display: inline-block;
	margin-top: 1rem;
	padding-right: 0.75rem;
	background-image: url('/20150810114905/assets/images/arrow-super-thick_black.svg');
	background-position: 100% 50%;
	background-size: 8px;
	background-repeat: no-repeat;
	font-family: Montserrat, sans-serif;
	color: #2d2e31;
	font-size: 0.9rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.cb-view-all:hover {
	background-color: transparent;
}

.search-dd-button {
	width: 3rem;
	margin-right: 2px;
	margin-left: 2px;
	padding: 0.75rem 1rem;
	background-image: url('/20150810114905/assets/images/search_blk.svg');
	background-position: 50% 50%;
	background-size: 17px 17px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.search-dd-button.search-top-button {
	height: 40px;
	background-image: url('/20150810114905/assets/images/search_wht.svg');
	background-size: 17px 17px;
	opacity: 1;
	cursor: pointer;
}

.search-button-accesible {
	align-self: center;
	list-style-type: none;
}

.search-dd-button.search-top-button:hover {
	background-color: rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.search-close {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	margin-left: 0.5rem;
	padding: 0.25rem;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: 0.5;
	font-size: 0.8rem;
	text-decoration: none;
	text-transform: uppercase;
}

.search-close:hover {
	opacity: 1;
}

.header-search-field-2 {
	display: inline-block;
	width: 70%;
	height: 2.5rem;
	margin-bottom: 0px;
	float: left;
}

.header-search-field-2.search-cover-field {
	border-radius: 4px 0px 0px 4px;
	background-color: #fff;
}

.search-session-message {
	height: 0px;
	margin-top: 0px;
	padding: 0px;
	background-color: transparent;
	opacity: 0;
	color: transparent;
}

.close-image {
	display: block;
}

.close-x-wrapper {
	width: 2rem;
	height: 2rem;
	float: left;
}

.close-x-wrapper:hover {
	opacity: 1;
}

.search-container-cover {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 20000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 0px;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.85);
}

.text-block-2 {
	display: none;
	margin-left: 0.25rem;
	color: #fff;
}

.header-search-form-2 {
	display: block;
	border-style: none;
}

.header-search-form-2.search-cover-form {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header-search-form-wrapper {
	width: 50%;
	margin-bottom: 0px;
	padding: 1rem;
	float: right;
	background-color: rgba(0, 0, 0, 0.5);
}

.header-search-form-wrapper.search-cover-wrapper {
	position: relative;
	top: 45%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 640px;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem;
	float: none;
	background-color: transparent;
}

.nav-menu-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.double-wrap {
	position: relative;
	width: 100%;
	margin-bottom: 4.44%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.double-wrap.individual-hidden {
	display: none;
}

.intro-wrap {
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 4.44%;
}

.news-wrap {
	position: relative;
	z-index: 1000;
	width: 100%;
}

.cb-1-inner-wrap {
	padding-left: 6vw;
}

.double-inner-wrap {
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	max-width: 1340px;
	min-width: 900px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	min-height: 550px;
}

.main-content-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 2rem;
}

.left-nav-link-3 {
	display: block;
	margin-bottom: 0.5rem;
	padding: 2px 0rem 2px 0.63rem;
	border-left: 2px solid transparent;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	color: #696b6d;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-link-3:hover {
	padding-right: 0rem;
	padding-left: 0.63rem;
	border-left-color: hsla(209.9999999999999, 0%, 77.33%, 1);
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #000;
}

.left-nav-link-3.w--current {
	border-left-color: #003a5d;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #000;
}

.left-nav-link-3.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-3.grandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-color: transparent;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-transform: none;
}

.left-nav-link-3.grandchild-link:hover {
	border-left-color: hsla(209.9999999999999, 0%, 77.33%, 1);
}

.left-nav-link-3.grandchild-link.w--current {
	border-left-color: #003a5d;
	background-image: none;
	font-weight: 600;
}

.left-nav-link-3.greatgrandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-color: transparent;
	background-image: url('/20150810114905/assets/images/bullet_ffffff.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	font-size: 0.8rem;
	line-height: 1.13rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-3.greatgrandchild-link:hover {
	border-left-color: hsla(209.9999999999999, 0%, 77.33%, 1);
	box-shadow: none;
	color: #000;
}

.left-nav-link-3.greatgrandchild-link.w--current {
	border-left-color: #a1c973;
	background-image: none;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #000;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 400;
}

.inside-content-sect {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3vw;
	padding-left: 3vw;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #f9f9f9;
}

.inside-page-banner-wrap {
	width: 100%;
	height: 250px;
	padding-right: 8rem;
	padding-left: 8rem;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-page-banner-wrap.inside-banner-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 250px;
	padding-right: 3vw;
	padding-left: 3vw;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.inside-page-banner-wrap.inside-banner-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 3vw;
	padding-left: 3vw;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.main-content-outer-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.content-outer-wrap {
	position: relative;
	z-index: 2;
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 4rem;
	border-top: 8px none #629edc;
}

.inside-page-wrapper {
	padding-top: 0px;
}

.main-content-inner-wrap-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.h3-right-column-title {
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 600;
}

.h2-right-column-title {
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 600;
}

html.w-mod-js *[data-ix="hero-header-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, -500px);
	-ms-transform: translate(0px, -500px);
	transform: translate(0px, -500px);
}

html.w-mod-js *[data-ix="hero-header-load-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, -100px);
	-ms-transform: translate(0px, -100px);
	transform: translate(0px, -100px);
}

@media screen and (max-width: 1170px) and (min-width: 992px) {
	.logo-link {
		width: 20vw;
		max-width: 250px;
	}
}

@media (max-width: 1100px) {
	.section.footer-section {
		padding: 3rem 5% 2rem;
	}
}

@media (max-width: 991px) {
	.container.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
	}

	.home-section {
		margin-bottom: 0px;
		padding-bottom: 4rem;
	}

	.cb-div-4 {
		width: 72.22%;
		max-width: none;
		min-width: 0px;
		margin-right: 22.22%;
		margin-bottom: 0rem;
		margin-left: 5.55%;
		background-color: hsla(0, 0%, 100%, 0.85);
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.main-nav-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.main-nav-link.mobile-duplicate {
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 2rem;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.main-nav-link.mobile-duplicate.w--current {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.nav-menu-wrap {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		background-color: #2d2e31;
	}

	.mobile-menu-button {
		padding: 0px;
	}

	.mobile-menu-button.w--open {
		background-color: transparent;
	}

	.hero-section {
		height: 80vh;
		min-height: 0px;
	}

	.hero-heading {
		font-size: 3rem;
		line-height: 3.8rem;
	}

	.cb-div-2.spotlight {
		width: 72.22%;
		margin-right: 22.22%;
		margin-bottom: 2.5rem;
		margin-left: 5.55%;
		padding-right: 8vw;
		background-color: hsla(0, 0%, 100%, 0.85);
	}

	.cb-div-2.testimonials {
		width: 72.22%;
		margin-right: 22.22%;
		margin-bottom: 2.5rem;
		margin-left: 5.55%;
		padding-top: 4rem;
		padding-right: 8vw;
		background-color: hsla(0, 0%, 100%, 0.85);
	}

	.cb-div-2.testimonials-image {
		width: 72.22%;
		min-height: 350px;
		margin-right: 5.55%;
		margin-bottom: 3.5rem;
		margin-left: 22.22%;
		padding-right: 8vw;
		background-color: hsla(0, 0%, 100%, 0.85);
	}

	.cb-div-2.banking-law-image {
		width: 72.22%;
		min-height: 350px;
		margin-right: 5.55%;
		margin-bottom: 2.5rem;
		margin-left: 22.22%;
		padding-top: 4rem;
		padding-right: 8vw;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		background-color: hsla(0, 0%, 100%, 0.85);
	}

	.cb-div-3 {
		width: 72.22%;
		margin-bottom: 2.5rem;
		margin-left: 22.22%;
		padding-top: 4rem;
		background-color: hsla(0, 0%, 100%, 0.85);
	}

	.cb-3-heading {
		font-size: 2.4rem;
		line-height: 3rem;
	}

	.footer-block {
		width: 100%;
		float: left;
	}

	.footer-grip-link {
		margin-left: 0rem;
	}

	.footer-container {
		-ms-grid-rows: auto auto auto;
		grid-template-rows: auto auto auto;
	}

	.footer-address-block {
		padding-left: 0.88rem;
	}

	.section.footer-section {
		padding-top: 2rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.footer-column-wrap {
		width: 50%;
	}

	.footer-column-wrap.contact-info-wrap {
		line-height: 1.75rem;
	}

	.footer-column-wrap.badges-wrap {
		width: 50%;
		line-height: 1.75rem;
	}

	.signoff-left {
		width: 100%;
		margin-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.link.footer-signoff:hover {
		color: #003a5d;
	}

	.footer-heading {
		border-left-color: #696b6d;
	}

	.signoff-outer {
		margin-top: 0rem;
		padding-top: 1rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px solid #696b6d;
	}

	.signoff-left-top {
		width: 100%;
		margin-bottom: 1rem;
		padding-right: 4rem;
		padding-left: 4rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.signoff-left-bottom {
		width: 100%;
		padding-right: 4rem;
		padding-left: 4rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.cb-div-1 {
		width: 72.22%;
		max-width: none;
		min-width: 0px;
		margin-right: 5.55%;
		margin-left: 22.22%;
		background-color: hsla(0, 0%, 100%, 0.85);
	}

	.social-wrap {
		padding-left: 0.88rem;
	}

	.intro-paragraph {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.left-nav-col {
		display: none;
	}

	.container-2 {
		max-width: 768px;
	}

	.container-2.inside-content-container {
		max-width: none;
		padding-top: 1rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.main-content {
		width: 100%;
		padding-top: 1rem;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		padding-top: 0rem;
		border-top: 1px solid rgba(0, 0, 0, 0.15);
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.mega-lower {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.search-alpha {
		width: 45%;
	}

	.search-divider {
		width: 10%;
	}

	.filter-search-wrap {
		width: 45%;
	}

	.alpha-link {
		margin-right: 1rem;
		margin-bottom: 0.75rem;
	}

	.letters-wrap {
		margin-right: -1rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.search-section-title {
		line-height: 1.2rem;
	}

	.nav-drop-toggle-mega {
		display: block;
	}

	.footer-upper-wrap {
		padding-right: 4rem;
		padding-bottom: 2rem;
		padding-left: 4rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(210, 2%, 42%, 0.6);
	}

	.mobile-link-label {
		display: inline-block;
		margin-top: 0rem;
		margin-bottom: 0rem;
		line-height: 1.2rem;
	}

	.mobile-menu-arrow {
		display: block;
		width: 15%;
		border-left: 1px solid hsla(210, 2%, 42%, 0.6);
		background-image: url('/20150810114905/assets/images/chevron-right_white.svg');
		background-position: 50% 50%;
		background-size: 12px 24px;
		background-repeat: no-repeat;
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: rgba(0, 0, 0, 0.25);
		opacity: 1;
	}

	.fixed-nav-button-wrap {
		padding-top: 24px;
		padding-bottom: 24px;
		background-color: transparent;
	}

	.fixed-nav-button-wrap:hover {
		background-color: transparent;
	}

	.nav-button-line-1 {
		width: 30px;
	}

	.nav-button-line-2 {
		width: 30px;
	}

	.nav-button-line-3 {
		width: 30px;
	}

	.search-dd-button.search-top-button {
		display: block;
		width: 70px;
		height: 70px;
		padding: 0rem;
		background-image: url('/20150810114905/assets/images/search_menu.svg');
		background-size: 24px 24px;
		opacity: 1;
	}

	.search-close {
		right: 6rem;
	}

	.search-container-cover {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.header-search-form-wrapper.search-cover-wrapper {
		top: 30%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.nav-menu-inner-wrap {
		display: block;
		width: 90%;
		margin-top: 100px;
		margin-left: 0vw;
		background-color: #2d2e31;
	}

	.double-wrap {
		margin-bottom: 0%;
	}

	.intro-wrap {
		margin-bottom: 2.5rem;
	}

	.cb-1-inner-wrap {
		padding-left: 0vw;
	}

	.double-inner-wrap {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		min-height: auto;
	}

	.double-inner-wrap.bottom {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.main-content-inner-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.inside-page-banner-wrap {
		height: 250px;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 300px;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 300px;
	}

	.main-content-outer-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.content-outer-wrap {
		height: 100%;
		padding-top: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.inside-page-wrapper {
		padding-top: 0px;
	}

	.main-content-inner-wrap-2 {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.megamenu-container {
		display: none !important;
	}

	.logo-link {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

@media (max-width: 767px) {
	.logo-link {
		padding-left: 10px;
	}

	.home-section {
		padding-bottom: 2rem;
	}

	.green-accent-bkgd {
		bottom: 0px;
		width: 100%;
	}

	.cb-div-4 {
		width: 89%;
		margin-right: 5.5%;
		margin-bottom: 2rem;
		margin-left: 5.5%;
		padding-bottom: 4rem;
		box-shadow: none;
	}

	.nav-menu-wrap {
		margin-left: 3vw;
	}

	.video-wrapper {
		display: none;
	}

	.mobile-menu-button {
		padding: 0px;
	}

	.mobile-menu-button.w--open {
		padding-top: 0px;
	}

	.hero-section {
		display: block;
		width: 100%;
		height: 450px;
		background-image: none;
	}

	.hero-caption {
		z-index: 1000;
		padding-bottom: 0px;
	}

	.hero-heading {
		padding-top: 1rem;
		padding-right: 1rem;
		padding-bottom: 1rem;
		background-color: rgba(0, 0, 0, 0.7);
		font-size: 2.9rem;
		line-height: 3rem;
	}

	.dot-icon-wrap {
		left: -97px;
		top: 34px;
		width: 500px;
		height: 500px;
		opacity: 0.8;
		max-width: 100%;
	}

	.cb-div-2.spotlight {
		width: 89%;
		margin-right: 5.5%;
		margin-bottom: 2rem;
		margin-left: 5.5%;
		padding-bottom: 4rem;
		box-shadow: none;
	}

	.cb-div-2.testimonials {
		width: 89%;
		margin-right: 5.5%;
		margin-bottom: 2rem;
		margin-left: 5.5%;
		padding-bottom: 4rem;
		box-shadow: none;
	}

	.cb-div-2.testimonials-image {
		width: 89%;
		margin-right: 5.5%;
		margin-bottom: 2rem;
		margin-left: 5.5%;
		padding-bottom: 4rem;
		box-shadow: none;
	}

	.cb-div-2.banking-law-image {
		width: 89%;
		margin-right: 5.5%;
		margin-bottom: 2rem;
		margin-left: 5.5%;
		padding-bottom: 4rem;
		box-shadow: none;
	}

	.cb-div-3 {
		width: 89%;
		margin-right: 5.5%;
		margin-bottom: 2rem;
		margin-left: 5.5%;
		padding-bottom: 4rem;
	}

	.cb-icon-wrap.blog-icon {
		left: -12px;
		top: 40px;
	}

	.cb-3-heading {
		font-size: 2.25rem;
	}

	.footer-block {
		display: inline-block;
	}

	.footer-grip-link {
		float: none;
	}

	.copyright-text {
		display: inline-block;
	}

	.footer-address-block {
		text-align: left;
	}

	.footer-column-wrap.badges-wrap {
		margin-right: auto;
		margin-left: auto;
		padding-left: 0.88rem;
	}

	.signoff-left {
		display: inline-block;
		margin-bottom: 1rem;
	}

	.signoff-left-top {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.signoff-left-bottom {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.cb-div-1 {
		width: 89%;
		margin-right: 5.5%;
		margin-left: 5.5%;
		padding-bottom: 4rem;
		box-shadow: none;
	}

	.button.search-button {
		width: 25%;
		font-size: 0.95rem;
		line-height: 1.8rem;
	}

	.container-2 {
		max-width: 550px;
	}

	.container-2.inside-content-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		font-size: 0.85rem;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.mobile-image {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		display: block;
		width: 100%;
		height: 100%;
		background-image: url('/20150810114905/assets/images/78052165-170667a.jpg');
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.search-alpha {
		width: 40%;
		padding-bottom: 0.75rem;
	}

	.filter-search-wrap {
		width: 50%;
	}

	.letters-wrap {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.green-accent-bkgd-lower {
		display: none;
	}

	.footer-upper-wrap {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.mobile-menu-arrow {
		background-size: 16px 16px;
	}

	.mobile-menu-arrow:hover {
		background-size: 16px 16px;
	}

	.fixed-nav-button-wrap {
		left: 0px;
	}

	.header-search-field-2.search-cover-field {
		width: 71%;
	}

	.header-search-form-wrapper.search-cover-wrapper {
		width: 400px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.nav-menu-inner-wrap {
		height: 100vh;
	}

	.intro-wrap {
		margin-bottom: 2rem;
	}

	.main-content-inner-wrap {
		padding-top: 0rem;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 200px;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 200px;
	}

	.main-content-outer-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.content-outer-wrap {
		padding-right: 0rem;
		padding-bottom: 2rem;
		padding-left: 0rem;
	}
}

@media (max-width: 479px) {
	.logo-link {
		padding-top: 14px;
		padding-bottom: 14px;
	}

	.cb-div-4 {
		padding: 2rem 1rem;
	}

	.logo-link {
		width: 210px;
	}

	.container.header-container {
		padding-right: 0vw;
		padding-left: 0.25rem;
	}

	.nav-menu-wrap {
		margin-left: 1rem;
	}

	.mobile-menu-button {
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
	}

	.hero-section {
		height: 400px;
	}

	.hero-heading {
		padding-left: 1rem;
		font-size: 2.4rem;
		line-height: 2.5rem;
	}

	.dot-icon-wrap {
		opacity: 0.8;
	}

	.cb-div-2.spotlight {
		padding: 2rem 1rem;
	}

	.cb-div-2.testimonials {
		padding: 2rem 1rem;
	}

	.cb-div-2.testimonials-image {
		min-height: 200px;
		padding: 2rem 1rem;
	}

	.cb-div-2.banking-law-image {
		padding: 2rem 1rem;
	}

	.cb-div-3 {
		padding: 2rem 1rem;
	}

	.blog-feed-heading {
		font-size: 1.35rem;
	}

	.cb-icon-wrap.quote-icon {
		display: none;
	}

	.cb-icon-wrap.scales-icon {
		display: none;
	}

	.cb-icon-wrap.person-icon {
		display: none;
	}

	.cb-icon-wrap.blog-icon {
		display: none;
	}

	.cb-3-heading {
		left: 0rem;
		padding-left: 0rem;
		border-left-style: none;
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.footer-block {
		margin-bottom: 0rem;
	}

	.footer-container {
		-ms-grid-rows: auto auto auto auto auto;
		grid-template-rows: auto auto auto auto auto;
	}

	.copyright-text {
		display: inline;
	}

	.footer-block-inside-wrap {
		margin-left: 0%;
	}

	.footer-icon-image {
		width: 30px;
		max-width: 20%;
		margin-right: 12px;
	}

	.section.footer-section {
		padding-bottom: 2rem;
	}

	.footer-column-wrap {
		width: 100%;
		padding-right: 0rem;
	}

	.footer-column-wrap.badges-wrap {
		width: 100%;
	}

	.signoff-left {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.link.footer-signoff {
		margin-bottom: 0.5rem;
	}

	.signoff-outer {
		width: 100%;
		margin-top: 0rem;
		padding-right: 0rem;
		text-align: center;
	}

	.signoff-left-top {
		display: block;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.signoff-left-bottom {
		display: block;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.cb-div-1 {
		padding: 2rem 1rem;
	}

	.social-wrap {
		margin-bottom: 2rem;
	}

	.intro-paragraph {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.container-2 {
		max-width: none;
	}

	.container-2.inside-content-container {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		background-color: hsla(0, 0%, 100%, 0.9);
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
	}

	.main-content {
		padding-top: 0.75rem;
	}

	.mega-lower {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.search-alpha {
		width: 100%;
		padding-bottom: 0rem;
	}

	.search-divider {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.filter-search-wrap {
		width: 100%;
	}

	.search-section-title {
		margin-top: 10px;
	}

	.vert-line {
		width: 40%;
		height: 1px;
		border-top: 1px solid hsla(0, 0%, 100%, 0.5);
	}

	.green-accent-bkgd-lower {
		top: 400px;
		height: 100%;
	}

	.footer-upper-wrap {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.mobile-link-label {
		font-size: 0.9rem;
		line-height: 1.2rem;
	}

	.mobile-menu-arrow {
		width: 20%;
	}

	.fixed-nav-button-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 50px;
		height: 50px;
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.search-dd-button.search-top-button {
		width: 50px;
		height: 50px;
		padding: 0rem;
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
	}

	.search-close {
		right: 5.5rem;
		margin-top: 0rem;
		margin-bottom: 1.5rem;
		padding-top: 0.25rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
	}

	.header-search-field-2 {
		width: 70%;
	}

	.header-search-field-2.search-cover-field {
		width: calc(100% - 105px);
	}

	.close-x-wrapper {
		width: 1.5rem;
		height: 1.5rem;
	}

	.text-block-2 {
		display: block;
		margin-right: 0.25rem;
		margin-left: 0rem;
		font-size: 0.9rem;
	}

	.header-search-form-2.search-cover-form {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
	}

	.header-search-form-wrapper {
		min-width: 100%;
	}

	.header-search-form-2.search-cover-form fieldset {
		width: 100%;
	}

	.header-search-form-wrapper.search-cover-wrapper {
		width: 260px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.nav-menu-inner-wrap {
		margin-top: 82px;
	}

	.inside-content-sect {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 175px;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 175px;
	}

	.content-outer-wrap {
		padding-right: 0rem;
		padding-bottom: 2rem;
		padding-left: 0rem;
	}

	.sign-off-right {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		line-height: 3;
		margin-top: -1rem;
		padding: 0 1rem;
	}
}

@media (max-width: 320px) {
	.sign-off-right {
		line-height: 1.7;
		margin-top: 0;
		padding: 0 1rem;
	}
}

/****************** Webflow ***********************/
/****************************************************/
html {
	font-size: 1rem;
}
/* FONT SMOOTHING */
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

a[href^=tel] {
	color: #ccc;
	text-decoration: none;
}
/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */
/* Mozilla-based browsers */
::-moz-selection {
	background-color: #003a5d;
	color: #d3d3d3;
}
/* Safari browser */
::selection {
	background-color: #003a5d;
	color: #d3d3d3;
}
/* ADJUSTMENTS FOR NARROWER DESKTOPS */
@media screen and (min-width: 1171px) and (max-width: 1240px) {
	.nav-menu-wrap {
		margin-left: 5vw;
	}
}

@media screen and (min-width: 992px) and (max-width: 1170px) {
	.hero-heading {
		padding-left: 2rem;
		font-size: 5rem;
		line-height: 5rem;
	}

	.nav-menu-wrap {
		margin-left: 2vw;
	}

	.container.header-container {
		padding-right: 3vw;
	}
}

@media screen and (min-width: 992px) and (max-width: 1070px) {
	.main-nav-link {
		font-size: 0.8125rem;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.search-button {
	border: 0px;
}

/****************** Accesible Menu *******************/
/*****************************************************/
.search-dd-button.search-top-button {
	float: right;
}

.accesible-navigation-menu ul.nav-menu-inner-wrap {
	margin: 0px;
	padding: 0px;
}

.accesible-navigation-menu ul.nav-menu-inner-wrap li.nav-drop-wrap {
	padding: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.accesible-navigation-menu ul.nav-menu-inner-wrap li a.main-nav-link,
.accesible-navigation-menu ul.nav-menu-inner-wrap li span.main-nav-link {
	display: flex;
	cursor: pointer;
	height: 100%;
	align-items: center;
	padding: 0px;
}

.accesible-navigation-menu ul.nav-menu-inner-wrap li a.main-nav-link span,
.accesible-navigation-menu ul.nav-menu-inner-wrap li span.main-nav-link span {
	padding: 0.5rem;
	margin: 0 auto;
	width: 100%;
}


.accesible-navigation-menu ul.nav-menu-inner-wrap li a.main-nav-link span:hover,
.accesible-navigation-menu ul.nav-menu-inner-wrap li span.main-nav-link span:hover,
.accesible-navigation-menu ul.nav-menu-inner-wrap li a.main-nav-link.active-item span,
.accesible-navigation-menu ul.nav-menu-inner-wrap li span.main-nav-link.active-item span,
.accesible-navigation-menu ul.nav-menu-inner-wrap li a.main-nav-link.active-item span:hover,
.accesible-navigation-menu ul.nav-menu-inner-wrap li span.main-nav-link.active-item span:hover,
.accesible-navigation-menu ul.nav-menu-inner-wrap li:hover .main-nav-link span {
	background-color: rgba(0, 0, 0, 0.25);
}

.accesible-navigation-menu li .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	min-width: 150px;
	left: 0;
	padding-top: 3px;
	transition: max-height 400ms ease 0s, opacity 500ms ease 0s;
	height: 0;
	color: #cdcdcd;
}

.accesible-navigation-menu ul.nav-menu-inner-wrap li.nav-drop-wrap#menu-item-blogs .container-wrapper {
	min-width: 300px;
}

.accesible-navigation-menu li .container-wrapper ul {
	display: none;
	padding: 0;
	margin-bottom: 0px;
	width: 100%;
	bottom: 0;
	margin-top: 0px;
	background-color: rgba(45, 46, 49, 0.9);
	max-height: 85vh;
	overflow-y: auto;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: thin;  /* Firefox */
}

.accesible-navigation-menu li .container-wrapper ul::-webkit-scrollbar {
	width: 5px;
}

.accesible-navigation-menu li .container-wrapper ul::-webkit-scrollbar-track {
	background: #fffffff2;
}

.accesible-navigation-menu li .container-wrapper ul::-webkit-scrollbar-thumb {
	background: #2d2e31;
}

.accesible-navigation-menu li .container-wrapper ul::-webkit-scrollbar-thumb:hover {
	background: #2d2e318f;
}

.accesible-navigation-menu li .container-wrapper ul li {
	display: inline-block;
	width: 100%;
	text-align: left;
	min-width: 170px;
}

.accesible-navigation-menu li .container-wrapper ul li a {
	display: block;
	width: 100%;
	margin-bottom: 0.125rem;
	padding: 0.75rem 2rem 0.75rem 0.75rem;
	color: #cdcdcd;
	font-size: 0.875rem;
	line-height: 1.1rem;
	font-weight: 400;
	letter-spacing: 0.02rem;
	text-decoration: none;
}

.accesible-navigation-menu li .container-wrapper ul li a:hover {
	letter-spacing: 0.02rem;
	background-color: rgba(45, 46, 49, 0.8);
	color: #fff;
}

.accesible-navigation-menu li.open .container-wrapper {
	opacity: 1;
	max-height: 1000px;
	height: auto;
}

.accesible-navigation-menu li.open .container-wrapper ul {
	display: inline-block;
}

#menu-item-attorneys:hover .megamenu-container {
	display: block;
	height: auto;
	opacity: 1;
}

#menu-item-attorneys:hover .megamenu-container .mega-inner-wrap {
	display: block;
}

#menu-item-attorneys {
	position: static;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none;
	}
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mobile-menu-button {
		display: none;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mobile-menu-button {
		display: block;
	}
}

.nav-button-line-1 {
	transform-style: preserve-3d !important;
	transition: transform 220ms ease 0s !important;
	transform: translateX(0px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(-0.001deg) !important;
}

.nav-button-line-2 {
	opacity: 1 !important;
}

.nav-button-line-3 {
	transform-style: preserve-3d !important;
	transition: transform 220ms ease 0s !important;
	transform: translateX(0px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(0.001deg) !important;
}

.mm-wrapper_opened .nav-button-line-1 {
	transform-style: preserve-3d !important;
	transition: transform 520ms ease 0s !important;
	transform: translateX(0px) translateY(8.999px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) !important;
}

.mm-wrapper_opened .nav-button-line-2 {
	opacity: 0 !important;
}

.mm-wrapper_opened .nav-button-line-3 {
	transform-style: preserve-3d !important;
	transition: transform 520ms ease 0s !important;
	transform: translateX(0px) translateY(-8.998px) translateZ(0px) rotateX(0deg) rotateY(0deg) rotateZ(45deg) !important;
}

.mobile-navigation-menu {
	background-color: #2d2e31;
}

.mobile-navigation-menu li .nav-link {
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 2.5rem;
	padding-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.mobile-navigation-menu li .nav-link:hover,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth):hover,
.mobile-navigation-menu li.active .nav-link {
	background-color: rgba(0, 0, 0, 0.25);
}

.mm-listitem,
.mm-listitem .mm-btn_next,
.mm-listitem .mm-btn_next:before,
.mm-listitem:after,
.mm-listview {
	border-color: hsla(210, 2%, 42%, 0.6);
}

.mobile-navigation-menu .mm-panel {
	padding-left: 0px;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #fff;
	border-width: 3px;
	width: 9px;
	height: 9px;
	right: 37px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 80px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 80px;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: #fff;
	cursor: pointer;
	font-family: Montserrat, sans-serif;
	color: #d3d3d3;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
	text-align: left;
}

.mm-panel_has-navbar .mm-navbar {
	height: 55px;
	border-bottom: 1px solid hsla(210, 2%, 42%, 0.6);
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -5px;
}

.mm-menu .mm-navbar a {
	margin-top: 7px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
	border-width: 3px;
	left: 17px;
	top: 3px;
}

/******************** Homepage **********************/
/*****************************************************/
.cb-3-heading a,
.cb-3-heading a:hover {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	border: none;
	text-decoration: none;
	box-shadow: none;
}

.cb-text p,
.cb-text li,
.cb-text span {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
}

.cb-text p:last-child {
	margin-bottom: 0px;
}

.cb-div-2 {
	min-height: 350px;
}

.cb-div-1 {
	min-height: 250px;
}

.cb-div-4 {
	min-height: 600px;
}

.down-arrow {
	display: block;
	width: 100%;
	text-align: right;
	margin-bottom: 20px;
}

.down-arrow .image-4 {
	width: 60px;
	margin-right: 44%;
}

@media (max-width: 991px) {
	.down-arrow {
		display: none;
	}
}

/********************** Slideshow *************************/
/**********************************************************/

/****************** Inside Page *******************/
/*************************************************/
td img {
	max-width: none;
}

img {
	height: auto;
	max-width: 100%;
	display: inline-block;
}

.inside-page-container.right-column-sidebar .main-content-inner-wrap,
.inside-page-container.no-sidebars .main-content-inner-wrap {
	padding-top: 0px;
}

.left-column-sidebar .main-content {
	width: calc(100% - 20% - 3vw - 1px);
}

.main-content-inner-wrap {
	width: 100%;
}

.left-nav-link-3.active {
	border-left-color: #003a5d;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #000;
}

.breadcrumbs-list li:first-child {
	margin-top: 1px;
}

.breadcrumbs-list li:last-child a {
	border-bottom: 0px;
}

.breadcrumb-divider {
	margin-left: 0.7rem;
}

@media (max-width: 991px) {
	.left-column-sidebar .main-content {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		margin-right: 0px;
		margin-left: 0px;
		float: none !important;
	}
}


/******************* Right Sidebar *******************/
/*****************************************************/
.right-side-col .box {
	padding-bottom: 0.4rem;
	margin-top: 20px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.right-side-col .box h4,
.right-side-col .box h3 {
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 600;
	margin-top: 0px;
	margin-bottom: 10px;
	font-family: Montserrat, sans-serif;
}


.right-side-col .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.links p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}


/*********************** Footer **********************/
/*****************************************************/
.phone-link.dekstop {
	display: inline-block;
}

.phone-link.mobile {
	display: none;
}

#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
	float: right;
	opacity: 0.5;
	transition: 300ms all ease;
}

#GRIPFooterLogo:hover {
	opacity: 1;
}

@media (max-width: 991px) {
	.phone-link.mobile {
		display: inline-block;
	}

	.phone-link.desktop {
		display: none;
	}

	#GRIPFooterLogo {
		float: none;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0 !important;
	border: 0px !important;
	line-height: 0;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.6rem 1rem !important;
	border: 2px solid #0077c8 !important;
	line-height: 1.1rem;
	box-shadow: 0 0 5px #000;
	z-index: 5000;
}

/* heading links */
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	border-bottom: 1px solid #003a5d;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #696b6d;
	text-decoration: none;
	cursor: pointer;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: transparent;
	color: #7c8186;
}

/* links */
.left-sidebar a,
.main-container a,
.right-side-col a,
.homebox a:not(.button),
#cookie-consent-form-container a {
	border-bottom: 1px solid #003a5d;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #696b6d;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.left-sidebar a:hover,
.main-container a:hover,
.right-side-col a:hover,
.homebox a:hover,
#cookie-consent-form-container a:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #696b6d;
	font-weight: 500;
}

.homebox span.button {
	padding: 0px;
	display: inline-block;
	margin: 0px;
}

.homebox span.button a {
	display: inline-block;
	min-width: 100px;
	padding: 0.8rem 1rem 0.7rem;
	border-radius: 4px;
	background-color: #003a5d;
	font-family: Montserrat, sans-serif;
	color: #d3d3d3;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 1rem;
}

.homebox span.button a:hover {
	box-shadow: 0 0 0 2px #003a5d;
}


/* buttons */
.main-container .button,
.main-container .primary,
.homepage-container .primary,
.cms_form_button.primary,
button[type="submit"],
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
.main-container .secondary,
.homepage-container .secondary,
.cms_form_button.secondary,
.main-container .tertiary,
.homepage-container .tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button {
	min-width: 100px;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding: 0.8rem 1rem 0.7rem;
	border-radius: 4px;
	background-color: #003a5d !important;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #d3d3d3 !important;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	border: 0px;
	display: inline-block;
}

.main-container .button a,
.right-sidebar-box .button a {
	color: #000;
}

.main-container .button:hover,
.main-container .primary:hover,
.homepage-container .primary:hover,
button[type="submit"]:hover,
.cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover,
.main-container .secondary:hover,
.homepage-container .secondary:hover,
.cms_form_button.secondary:hover,
.main-container .tertiary,
.homepage-container .tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	box-shadow: 0 0 0 2px #003a5d;
	color: #d3d3d3 !important;
	cursor: pointer;
}

.main-container .secondary,
.homepage-container .secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	background-color: #d6dce0 !important;
	color: #2e2e2e !important;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	border: 0px;
}

.main-container .secondary:hover,
.homepage-container .secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #fff !important;
	color: #003a5d !important;
}

.main-container .tertiary,
.homepage-container .tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	min-width: 60px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.25rem 0.75rem;
	border: 2px solid #c4ccd1 !important;
	background-color: transparent !important;
	color: #585b5d !important;
	font-size: 0.9rem;
	line-height: 1.4rem;
	box-shadow: 0 0 0 2px transparent;
}

.main-container .tertiary:hover,
.homepage-container .tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #f2f2f2 !important;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	box-shadow: 0 0 0 2px #003a5d;
	color: #000 !important;
}

.primary,
.primary:hover {
	color: #d3d3d3 !important;
	border-bottom: 0px !important;
}

.secondary {
	color: #2e2e2e !important;
	border-bottom: 0px !important;
}

.secondary:hover {
	color: #2e2e2e !important;
}

.tertiary {
	color: #585b5d !important;
	border-bottom: 0px !important;
}

.tertiary:hover {
	color: #000 !important;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #4fb402;
	border-bottom: 2px solid #4fb402;
	background-color: hsla(94, 73%, 76%, 0.55);
	font-weight: 500;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #e81c2e;
	border-bottom: 2px solid #e81c2e;
	background-color: rgba(255, 190, 195, 0.4);
	font-weight: 500;
}

#message.success p:last-child,
#message.error p:last-child {
	margin-bottom: 0px;
}

/*tables*/
table.styled {
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid #2e2e2e;
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #033466;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #034a94;
	color: #ffffff;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	box-shadow: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ebeff2;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.main-container input[type="text"],
.main-container input[type="tel"],
.main-container input[type="password"],
.main-container input[type="email"],
.main-container select,
.main-container textarea,
.right-side-col input[type="text"],
.right-side-col input[type="tel"],
.main-container input[type="search"] {
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
}

.main-container textarea {
	resize: vertical;
}

.main-container label,
.main-container .cms_label,
.main-content label,
.homebox label {
	font-weight: 500;
}

@media (max-width: 991px) {
	.main-container input[type="text"],
	.main-container input[type="tel"],
	.main-container input[type="password"],
	.main-container input[type="email"],
	.main-container select,
	.main-container textarea,
	.right-side-col input[type="text"],
	.right-side-col input[type="tel"],
	.main-container input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(0, 0, 0, 0.15);
	border: 0px;
	color: rgba(0, 0, 0, 0.15);
	border-top: 1px solid;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

/*********************** Modules ********************/
/****************************************************/
/* ui forms */
.ui-form-field.required .ui-form-label em {
	color: #d00606;
}


/* gdpr */
#cookie-consent-form-container form .consent-header img {
	margin: 0 auto;
	display: block;
	float: none;
}

#cookie-consent-form-container form .consent-header {
	padding-top: 20px;
}

#cookie-consent-container.minimized {
	bottom: 10px;
	right: 10px;
	border-top: 0;
}

#cookie-consent-container .consent-actions a.consent-all-trigger,
#cookie-consent-container .consent-actions a.reject-all-trigger {
	color: #d3d3d3;
}

#cookie-consent-container {
	background: #d3d3d3;
	color: #003a5d;
	border-top: 3px solid #003a5d;
}

#cookie-consent-container.minimized {
	background: #262626;
	color: #fff;
}

#cookie-consent-container .consent-actions a {
	color: #003a5d;
	border-bottom: 1px solid #003a5d;
}

#cookie-consent-container .consent-dismiss-trigger img {
	filter: contrast(0) sepia(120%) invert(1) contrast(2) saturate(0.6);
}

@media (max-width: 767px) {
	#cookie-consent-container {
		font-size: 0.8rem;
		line-height: 1.1rem;
	}
}

/* events */
#events_module.cms_list .cms_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* files */
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1.5rem 0px 0px 0px;
	padding: 1.5rem 0px 0px 0px;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
}

.right-side-col .box.filter .filter-row .select2-input.select2-default:focus {
	outline: 1px solid #5e9ed6 !important;
}


.right-side-col .box.filter .filter-row .select2-container,
.right-side-col .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-side-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices,
.right-side-col .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #cccccc;
	background: #fff;
	padding: 8px 12px;
	height: auto;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	height: 0px;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: rgba(0, 0, 0, 0.15);
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
	font-size: 0.9rem;
	margin-bottom: 0pc;
}

/* news */
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-bottom: 0.8rem;
	margin-top: 1.5rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */
.right-side-col .moduleDocuments-rss-feed .moduleDocuments-rss-feed-link {
	border-bottom: 0px;
}

/* submission forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_content .testimonial-link {
	border-bottom: 0px;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

/* newsletter */
#newsletters_module.list .item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin: 1.5rem 0px 1.5rem 0px;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
		padding-bottom: 15px;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 300px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 20px 0;
	padding-bottom: 7px;
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: bold;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

/* search */
.collection.search-collection .search-node {
	border-top: rgba(0, 0, 0, 0.15) solid 1px;
	margin: 1.5rem 0 0 0;
	padding: 1.3rem 0 0 0;
}

.collection.search-collection .search-field-title {
	line-height: 1.6rem;
}

/* posts */
#posts_module.form .form_item table tbody,
#posts_module.form .form_item table tbody tr {
	display: block;
	width: 100%;
}

.posts-comment-form-required-info .required_information {
	color: #d00606;
}

.posts-collection > .posts-node {
	border-top: rgba(0, 0, 0, 0.15) dotted 1px;
	margin: 1.5rem 0 0 0;
	padding: 1.2rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node .posts-comments {
	margin-top: 2rem;
}

.posts-node #post-comments .post-comments-title {
	background-color: #003a5d;
	color: #d3d3d3;
	font-family: inherit;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.posts-blocks-author-bio .bio-block-main,
.posts-blocks-author-bio .bio-block-contact-details {
	margin-top: 10px;
}

.posts-blocks-author-bio .bio-block-name {
	margin-bottom: 0px !important;
}

@media (max-width: 991px) {
	#posts_module.form .ui-form-input.ui-form-text,
	#posts_module.form .ui-form-input.ui-form-text input[type="text"] {
		width: 100% !important;
	}
}


.right-side-col .box.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe a,
.right-side-col .box.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed a {
	border-bottom: 0px;
}

.posts-blocks.posts-blocks-author-latest-posts .posts-blocks-author-latest-posts-abstract {
	margin-bottom: 15px;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-side-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-side-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
	display: inline-block;
	width: 100%;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author {
	margin-bottom: 0px;
}

.right-side-col .box.posts-blocks.posts-blocks-tags {
	padding-bottom: 20px;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 39px;
}

.right-side-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category:after {
	content: "";
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	margin: 0px;
	min-width: auto;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form {
	margin-bottom: 10px;
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 150px;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* videos */
#video_module.cms_list .cms_list_item {
	margin-top: 26px;
	padding-bottom: 16px;
	border-bottom: 1px solid #e1e3e0;
}

#video_module.cms_list .cms_list_item:first-child {
	margin-top: 0px;
}

#video_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#video_module.cms_list .cms_list_item table td.cms_video {
	padding-right: 15px;
}

#video_module.cms_list .cms_list_item table td.cms_video img {
	max-width: 200px;
	height: auto;
}

@media (max-width: 991px) {
	#video_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#video_module.cms_list .cms_list_item table td.cms_video {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/***************** Content Boxes ********************/
/****************************************************/
/* news */
#news_module.homepage_contentbox .cms_list_item {
	padding-bottom: 1rem;
	border-bottom: 1px solid hsla(210, 2%, 42%, 0.4);
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 a {
	color: #4b4c50;
	text-decoration: none;
	border: 0px;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 a:hover {
	background-color: transparent;
}

#news_module.homepage_contentbox .cms_list_item .cms_date h3 {
	margin: 0px;
	font-size: 1rem;
	line-height: 1.4rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_description,
#news_module.homepage_contentbox .cms_list_item .cms_description p {
	font-family: 'Open Sans', sans-serif;
	color: #2d2e31;
	font-size: 1rem;
	line-height: 1.6rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_description p,
#news_module.homepage_contentbox .cms_list_item .cms_description p:last-child {
	margin-bottom: 0.5rem;
}

#news_module.homepage_contentbox > a:last-of-type {
	display: inline-block;
	margin-top: 1rem;
	padding-right: 0.75rem;
	background-image: url('/20150810114905/assets/images/arrow-super-thick_black.svg');
	background-position: 100% 50%;
	background-size: 8px;
	background-repeat: no-repeat;
	font-family: Montserrat, sans-serif;
	color: #2d2e31;
	font-size: 0.9rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

#news_module.homepage_contentbox > a:last-of-type:hover {
	background-color: transparent;
}

.homebox a.cb-view-all,
.homebox a.slider-contents-feed-read-more {
	padding-right: 0.75rem;
	background-image: url('/20150810114905/assets/images/arrow-super-thick_black.svg');
	background-position: 92% 50%;
	background-size: 8px;
	background-repeat: no-repeat;
	font-family: Montserrat, sans-serif;
	color: #2d2e31;
	font-size: 0.9rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
	border: 0px;
	padding: 0.2rem 1rem 0.1rem 0.5rem;
	line-height: 1.5rem;
}

.homebox a.cb-view-all {
	background-position: 94% 50%;
	padding: 0.1rem 1.1rem 0.1rem 0.5rem;
}

.homebox a.cb-view-all:hover,
.homebox a.slider-contents-feed-read-more:hover {
	background-color: transparent;
}


@media (max-width: 479px) {
	#news_module.homepage_contentbox .cms_list_item .cms_title h3 a {
		font-size: 1.35rem;
	}
}

/* testimonials */
#testimonials_module_contentbox.contentbox_item .author-name,
#testimonials_module_contentbox.contentbox_item .company {
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	text-transform: uppercase;
}

#testimonials_module_contentbox.contentbox_item .description-box {
	margin-bottom: 0.7rem;
}

/* posts */
.cb-text .slider-contents-feed-item-wrap {
	padding-bottom: 1rem;
	border-bottom: 1px solid hsla(210, 2%, 42%, 0.4);
}

.cb-text .slider-contents-feed-item-title {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	font-family: Montserrat, sans-serif;
	font-size: 1.5rem;
	line-height: 1.875rem;
	font-weight: 500;
}

.cb-text .slider-contents-feed-item-title a {
	font-family: Montserrat, sans-serif;
	font-size: 1.5rem;
	line-height: 1.875rem;
	font-weight: 500;
	color: #4b4c50;
	text-decoration: none;
	border: 0px;
}

.cb-text .slider-contents-feed-item-title a:hover {
	background-color: transparent;
}

.cb-text .slider-contents-feed-read-more {
	padding-right: 0.75rem;
	background-image: url('/20150810114905/assets/images/arrow-super-thick_black.svg');
	background-position: 100% 50%;
	background-size: 8px;
	background-repeat: no-repeat;
	font-family: Montserrat, sans-serif;
	color: #2d2e31;
	font-size: 0.9rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.cb-text .slider-contents-feed-read-more:hover {
	background-color: transparent;
}

.cb-text .slider-contents-feed-summary {
	font-family: 'Open Sans', sans-serif;
	color: #2d2e31;
	font-size: 1rem;
	line-height: 1.6rem;
}

.posts-collection.homepage-contentbox.article-items .article {
	width: 100% !important;
	margin-bottom: 20px;
}

.posts-collection.homepage-contentbox.article-items .article-image {
	width: auto;
	max-width: 320px;
	height: auto;
}

.posts-collection.homepage-contentbox.article-items .article-image-link {
	border: 0px;
}

@media (max-width: 479px) {
	.cb-text .slider-contents-feed-item-title a {
		font-size: 1.35rem;
	}
}

/* events */
#events_module.homepage_contentbox .date {
	width: 100px;
	font-weight: bold;
}

#events_module.homepage_contentbox .title {
	width: calc(100% - 100px);
}

@media (max-width: 991px) {
	#events_module.homepage_contentbox td,
	#events_module.homepage_contentbox .title,
	#events_module.homepage_contentbox .date {
		display: inline-block;
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
		text-align: left;
		padding-bottom: 10px;
	}

	#events_module.homepage_contentbox .title {
		padding-bottom: 20px;
	}
}

/* submission form */
#submissionforms_module.cms_form .cms_required em {
	color: #d00606;
}

#submissionforms_module.cms_form .required_information em {
	color: #d00606;
}

#submissionforms_module.homepage_contentbox {
	text-align: left;
	padding: 10px;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	color: inherit;
}

/* video  */
.homepage-featured-video .mp4-video,
.homepage-featured-video .iframe-video {
	background-size: cover;
	background-position: 50% 50%;
	position: absolute;
	right: -100%;
	bottom: -100%;
	top: -100%;
	left: -100%;
	margin: auto;
	min-width: 100%;
	min-height: 100%;
	z-index: -100;
}

/**************  Attorneys Modules ********************/
/****************************************************/
/* practice area list */
#module-practiceareas.list-view .area-children {
	display: none;
}

#module-practiceareas.list-view .area-image {
	display: none;
}


#module-practiceareas.list-view .practice-area {
	margin: 0 0 1.3em;
	padding: 0px;
	border: 0px;
}

#module-practiceareas.list-view .area-title {
	border-bottom: 1px solid #003a5d;
	padding-bottom: 2px;
}

#module-practiceareas.list-view .area-title a {
	border: 0px;
}

#module-practiceareas.thumbnail-view .main-list-related-areas-column {
	display: none;
}

#module-practiceareas.thumbnail-view .main-list-image-column {
	width: 100%;
	padding: 0px 0px 5px 0px;
}

#module-practiceareas.thumbnail-view .main-list-summary-column,
#module-practiceareas.thumbnail-view .main-list-summary-column .main-list-item-summary-wrap,
#module-practiceareas.thumbnail-view .main-list-summary-column.no-thumbnail {
	width: 100%;
}

#module-practiceareas.thumbnail-view .main-list-item-summary-wrap {
	margin: 0px;
	border: 0px;
}

.attr-subtitle {
	margin-top: 0px;
	margin-bottom: 10px;
	color: #767676;
	font-style: italic;
	font-weight: 300;
	text-transform: uppercase;
}

/* practice area detail */
.a_practice-image {
	width: 100%;
	height: 300px;
	margin-bottom: 30px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.a_profile-block_colored {
	margin-top: 10px;
	padding: 20px;
	border-top: 3px solid #003a5d;
	background-color: #f3f3f3;
	margin-bottom: 30px;
	display: inline-block;
	width: 100%;
}

.a_profile-block_colored h2 {
	margin-top: 0px;
}

.a_photo-list-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.a_list_photo {
	margin-bottom: 25px;
	padding-right: 5px;
	padding-left: 5px;
	-webkit-flex-basis: 20%;
	-ms-flex-preferred-size: 20%;
	flex-basis: 20%;
	text-align: center;
	border-bottom: 0px !important;
}

.placeholder-img {
	margin-bottom: 5px;
	border: 1px solid #a8a8a8;
	background-color: #dfdfdf;
}

.a_industry-list {
	padding-left: 0px;
	list-style-type: none;
}

.a_industry-list li {
	margin-bottom: 10px;
}

@media (max-width: 991px) {
	.a_practice-image {
		height: 250px;
	}
}

@media (max-width: 767px) {
	.a_practice-image {
		height: 200px;
	}

	.a_list_photo {
		width: 32%;
		-webkit-flex-basis: 32%;
		-ms-flex-preferred-size: 32%;
		flex-basis: 32%;
	}
}


@media (max-width: 479px) {
	.a_practice-image {
		height: 150px;
	}

	.a_list_photo {
		width: 50%;
		margin-right: 0px;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}
}

/* Attorney advacne search form */
.attorney-profiles-search-header-wrap {
	margin-bottom: 3px;
	background-color: #d6dce0 ;
}

.row.attorney-profiles-search-by-name-row {
	padding: 1.25em;
}

.row.attorney-profiles-search-by-name-row {
	padding-right: 0.75em;
	padding-bottom: 1.5em;
	padding-left: 0.75em;
}

.attorney-profiles-search-title {
	margin-bottom: 0em;
	margin-top: 14px;
	font-size: 1.125rem;
	line-height: 1.25em;
}

.attorney-profiles-advanced-search-btn:hover {
	color: #c5ca46;
}

.attorney-profiles-search-wrapper {
	overflow-x: hidden;
	overflow-y: hidden;
	margin-bottom: 1em;
}

.attorney-profiles-search-form {
	padding: 1.3rem 1rem 1rem 1rem;
	background-color: #d6dce0;
	display: none;
	transform: translateX(0px) translateY(0px) translateZ(0px);
}

.column.advanced-search-form-column {
	border-right: 2px solid rgba(105, 106, 108, 0.25);
	padding-right: 20px;
}

.advanced-search-form-split {
	float: left;
	margin-bottom: 1rem;
	width: 48%;
}

.form-field.attorney-profiles-form-field {
	max-height: 32px;
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
}

.button.attorney-profiles-search-button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	min-height: 36px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	width: 66%;
	margin-bottom: 0px;
}

.advanced-search-form-split {
	float: left;
	margin-bottom: 1rem;
	width: 48%;
}

.advanced-search-form-split h3 {
	margin-top: 12px;
	margin-bottom: 0px;
	font-size: 1.125rem;
	line-height: 19px;
	color: #000;
}

.advanced-search-form-split.practice-area {
	float: right;
}

.column.advanced-search-alpha-column {
	padding-left: 18px;
}


.advanced-search-alpha-wrap {
	background-image: linear-gradient(rgba(0, 0, 0, 0.21), rgba(0, 0, 0, 0));
	display: inline-block;
	padding-left: 20px;
	padding-right: 20px;
}

.advanced-search-alpha-wrap h3 {
	margin-top: 10px;
	font-size: 1.125rem;
	line-height: 19px;
	margin-bottom: 0px;
	color: #000;
}

.advanced-search-alpha-descriptor {
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: #000;
}

.advanced-search-alpha-text {
	font-size: 1.75rem;
	line-height: 1.2em;
	margin-bottom: 0.5em;
	margin-top: 0;
	text-align: justify;
	color: #696b6d;
}

.advanced-search-alpha-link {
	color: #2d2e31;
	line-height: 1rem;
	text-decoration: none;
}

.attorney-profiles-advanced-search-btn {
	display: inline-block;
	margin-top: 0.5em;
	padding: 0.25em 1rem 0.25em 0.25em;
	float: right;
	background-image: url('/20160107131822/assets/images/arrow-super-thick_black_down.png');
	background-position: 100% 53%;
	background-size: 11px auto;
	background-repeat: no-repeat;
	color: #2d2e31;
	text-transform: lowercase;
}

.attorney-profiles-advanced-search-btn:hover {
	text-decoration: none;
	color: #2d2e31;
}

@media (max-width: 991px) {
	.attorney-profiles-search-form {
		padding-bottom: 10px;
	}

	.column.advanced-search-alpha-column {
		padding-left: 0;
		padding-right: 0;
	}

	.advanced-search-alpha-wrap {
		margin-top: 0;
		text-align: center;
		width: 100%;
	}

	.advanced-search-alpha-text {
		display: inline-block;
		font-size: 1.75rem;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0.25em;
	}

	.column.advanced-search-form-column {
		border-bottom: 2px solid rgba(105, 106, 108, 0.25);
		border-right-style: none;
		border-right-width: 0;
		padding-bottom: 20px;
		padding-right: 10px;
	}
}

@media (max-width: 767px) {
	.attorney-profiles-search-header-wrap {
		margin-right: 10px;
		margin-left: 10px;
	}

	.attorney-profiles-search-wrapper {
		margin-right: 10px;
		margin-left: 10px;
	}

	.attorney-profiles-search-form {
		padding-left: 0.75em;
		padding-right: 0.75em;
		padding-top: 0.5em;
	}

	.advanced-search-form-split {
		margin-bottom: 0rem;
		width: 100%;
	}

	.advanced-search-form-split {
		margin-bottom: 0.75rem;
		width: 100%;
	}

	.attorney-profiles-advanced-search-btn {
		float: left;
		margin-top: 0;
	}
}


/* Attorneys photo list */
.d_list.photos {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 3px;
}

.d_photo {
	width: 75px;
	margin-right: 20px;
	float: left;
}

.d_list_photo {
	max-width: 32%;
	margin-bottom: 20px;
	padding: 10px;
	-webkit-flex-basis: 32%;
	-ms-flex-preferred-size: 32%;
	flex-basis: 32%;
	background-color: #fff;
	border-bottom: 0px !important;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.d_list_photo_name {
	margin-bottom: 0px;
	border-bottom: 1px solid #003a5d;
}


@media (max-width: 767px) {
	.d_list_photo {
		max-width: 48%;
		-webkit-flex-basis: 48%;
		-ms-flex-preferred-size: 48%;
		flex-basis: 48%;
	}
}

@media (max-width: 479px) {
	.d_list_photo {
		max-width: 100%;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}
}

/* Alphabetical */
/* Practice */
#module-attorneys.alpha a,
#module-attorneys.practice a {
	border: 0px;
}

.d_list-subheading {
	color: #2d2e31;
	font-size: 1.437rem;
	line-height: 28px;
	font-weight: 400;
	font-weight: bold;
	margin-bottom: 0px;
}

.d_list-item {
	margin-bottom: 10px;
	display: flex;
}

.d_list-name {
	display: inline-block;
	width: 40%;
	color: #555;
	flex: 1;
}

.d_list-phone {
	display: inline-block;
	width: 20%;
	margin-bottom: 0px;
	flex: 1;
}

.d_list-email {
	display: inline-block;
	padding-left: 25px;
	background-image: url('/20160108122112/assets/images/email-icon.svg');
	background-position: 0px 50%;
	background-size: 25px 15px;
	background-repeat: no-repeat;
	color: #555;
	height: 15px;
	width: 25px;
	margin-right: 10px;
	margin-left: 10px;
}

.d_list-vcard {
	display: inline-block;
	padding-left: 25px;
	background-image: url('/20160108122112/assets/images/vcard-icon.svg');
	background-position: 0px 50%;
	background-size: 25px 15px;
	background-repeat: no-repeat;
	color: #555;
	height: 15px;
	width: 25px;
	margin-right: 10px;
	margin-left: 10px;
}

@media (max-width: 767px) {
	.d_list-item {
		padding: 10px;
		display: inline-block;
		width: 100%;
	}

	.d_list-name {
		display: block;
		width: 100%;
		margin-bottom: 5px;
		font-size: 1.25rem;
		line-height: 28px;
	}

	.d_list-phone {
		display: block;
		width: 100%;
	}

	.d_list-email {
		display: block;
		width: auto;
		margin-top: 10px;
		padding-left: 30px;
		margin-left: 10px;
		margin-right: 0px;
		float: left;
	}

	.d_list-vcard {
		display: block;
		width: auto;
		margin-top: 10px;
		padding-left: 30px;
		font-size: 1.125rem;
		margin-left: 0px;
		margin-right: 0px;
		float: left;
	}
}

/* Attorney details view */
.a_profile-block {
	padding-top: 30px;
	padding-bottom: 30px;
	border-top: 1px solid #cfcfcf;
}

.a_profile-block .publications {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.a_profile-block .publications li {
	padding: 0px;
	margin: 0px 0px 10px 0px;
}

.a_profile-block_colored.half-container {
	display: inline-block;
	width: 100%;
}

.a_profile-block-half {
	width: 50%;
	float: left;
}

.a_profile-block-half.full-box {
	width: 100%;
}

.a_heading {
	margin-top: 0px;
}

.a_heading.top {
	margin-top: 10px;
	padding-top: 0px;
	border-top-style: none;
	border-top-color: #000;
}

.a_profile-img {
	max-width: 175px;
	height: auto;
	margin-right: 20px;
	float: left;
	border: 1px solid #536b7a;
}

.a_contact-info {
	width: 100%;
	display: inline-block;
}

.a_contact-info.with-image {
	width: calc(100% - 195px);
}

.a_contact-item {
	margin-bottom: 5px;
}

.a_contact-item.divider {
	padding-top: 10px;
	border-top: 1px solid #cfcfcf;
}

.a_contact-label {
	display: inline-block;
	width: 110px;
	color: #615b5b;
	font-size: 0.9375rem;
	line-height: 23px;
}

.a_contact-data {
	display: inline-block;
	font-size: 0.9375rem;
	width: calc(100% - 110px);
}

.a_profile-a {
	display: inline-block;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #65a4cc;
}

.a_contact-email {
	display: none;
	margin-bottom: 10px;
	padding-top: 3px;
	padding-left: 35px;
	background-image: url('/20150810114905/assets/images/email-icon.svg');
	background-position: 0px 50%;
	background-size: auto 15px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #65a4cc;
	font-size: 0.875rem;
	line-height: 22px;
}

.a_contact-vcard {
	display: inline-block;
	margin-right: 15px;
	padding-top: 3px;
	padding-left: 30px;
	background-image: url('/20150810114905/assets/images/vcard-icon.svg');
	background-position: 0px 50%;
	background-size: auto 15px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #65a4cc;
	font-size: 0.875rem;
	line-height: 22px;
}

.a_contact_linkedin {
	display: inline-block;
	margin-right: 15px;
	padding-top: 3px;
	padding-left: 21px;
	background-image: url('/20150810114905/assets/images/linkedin-icon.svg');
	background-position: 0px 50%;
	background-size: auto 18px;
	background-repeat: no-repeat;
	color: #65a4cc;
	font-size: 0.875rem;
	line-height: 22px;
}

.a_contact-blog {
	display: inline-block;
	margin-right: 15px;
	padding-top: 3px;
	padding-left: 33px;
	background-image: url('/20150810114905/assets/images/blog-icon.svg');
	background-position: 0px 50%;
	background-size: auto 18px;
	background-repeat: no-repeat;
	color: #65a4cc;
	font-size: 0.875rem;
	line-height: 22px;
}

.a_contact-rss {
	display: inline-block;
	margin-right: 15px;
	padding-top: 3px;
	padding-left: 19px;
	background-image: url('/20150810114905/assets/images/rss-icon.svg');
	background-position: 0px 50%;
	background-size: auto 18px;
	background-repeat: no-repeat;
	color: #65a4cc;
	font-size: 0.875rem;
	line-height: 22px;
}

.a_contact-print {
	display: inline-block;
	padding-left: 25px;
	background-image: url('/20150810114905/assets/images/print-icon.svg');
	background-position: 0px 50%;
	background-size: auto 18px;
	background-repeat: no-repeat;
	color: #65a4cc;
	font-size: 0.875rem;
	line-height: 22px;
	margin-right: 15px;
}

.a_contact-item-link {
	border-bottom: 0px !important;
}

.a_contact-item.flex {
	display: flex;
	margin-bottom: 5px;
}

.a_contact-item h1,
.a_contact-item h2,
.a_contact-item h3,
.a_contact-item h4,
.a_contact-item h5,
.a_contact-item h6 {
	margin-top: 0px;
}

.a_contact-item h5,
.a_contact-item h6 {
	line-height: 23px;
}

.a_contact-data p:last-child {
	margin-bottom: 0px;
}

.a_profile-posts {
	margin-bottom: 10px;
}

@media (max-width: 991px) {
	.a_profile-block-half {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.a_profile-img {
		margin-bottom: 20px;
	}

	.a_contact-info.with-image {
		width: 100%;
	}
}

@media (max-width: 479px) {
	.a_profile-img {
		display: block;
		margin-right: auto;
		margin-bottom: 20px;
		margin-left: auto;
		float: none;
	}

	.a_contact-info {
		width: 100%;
	}

	.a_contact-item.email {
		display: none;
	}

	.a_contact-email {
		display: block;
	}

	.a_contact-vcard {
		display: block;
		margin-right: 0px;
		margin-bottom: 10px;
		padding-top: 3px;
	}

	.a_contact_linkedin {
		display: block;
		margin-right: 0px;
		margin-bottom: 10px;
		padding-top: 3px;
		padding-left: 35px;
	}

	.a_contact-blog {
		display: block;
		margin-right: 0px;
		margin-bottom: 10px;
		padding-top: 3px;
		padding-left: 35px;
	}

	.a_contact-rss {
		display: block;
		margin-right: 0px;
		margin-bottom: 10px;
		padding-top: 3px;
		padding-left: 35px;
	}


	.a_contact-print {
		display: block;
		margin-right: 0px;
		margin-bottom: 10px;
		padding-top: 3px;
		padding-left: 35px;
		padding-left: 35px;
	}
}

/* Alert Region */

.alert-header-section {
	display: block;
	overflow: hidden;
	background-color: #c5c5c5;
}

.alert-header-wrapper {
	display: block;
	padding-top: 0px;
	justify-content: space-around;
	align-items: center;
	color: #fff;
}

.alert-container {
	display: flex;
	height: auto;
	max-width: none;
	padding-right: 4vw;
	padding-left: 4vw;
	justify-content: space-between;
	align-items: center;
}

.alert-exit-icon {
	position: absolute;
	top: auto;
	right: 4vw;
	z-index: 500;
	width: 25px;
	height: 25px;
	margin-top: 0em;
	margin-right: 0.5rem;
	margin-left: 0em;
	padding-right: 15px;
	padding-left: 0px;
	float: right;
	border-bottom-style: none;
	background-image: url('/20150810114905/assets/images/exit-icon-black.svg');
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: hsla(0, 0%, 100%, 0.8);
	font-size: 0.75rem;
	line-height: 1.5em;
	letter-spacing: 0.05em;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.alert-header-content-wrapper {
	display: flex;
	padding: 0.75rem 0em;
	align-items: center;
	font-size: 0.875em;
}

.alert-header-text {
	display: flex;
	align-items: center;
	align-self: center;
	flex-grow: 1;
	flex-shrink: 1;
}

.alert-header-content-wrapper p,
.alert-header-content-wrapper a {
	margin-top: 0em;
	margin-right: 1rem;
	margin-bottom: 0em;
	font-family: 'Open Sans', sans-serif;
	color: #2d2e31;
	font-size: 1.1rem;
	line-height: 1.15rem;
	font-weight: 600;
	letter-spacing: 0px;
	text-align: center;
}

.alert-header-content-wrapper .cms-website-content-region {
	display: flex;
}

@media screen and (max-width: 479px) {
	.alert-exit-icon {
		top: auto;
		right: 0.5rem;
		margin-top: 0rem;
		margin-right: 0rem;
	}
}
